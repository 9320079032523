import { coreModelComponentMap, CoreModelReading } from '@3fourteen/core';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { BlockedText } from 'components';

interface CoreModelTableProps {
  readings: CoreModelReading[];
  date: string;
  condensed?: boolean;
  readingOnly?: boolean;
  isFetching?: boolean;
  Legend?: React.ComponentType;
}

const condensedPadding = { paddingTop: 12, paddingBottom: 12 };

function CoreModelTable({
  readings,
  date,
  condensed = true,
  readingOnly = false,
  isFetching = false,
  Legend,
}: CoreModelTableProps) {
  const isLoading = isFetching || !readings;

  const overallReading = readings?.find(
    ({ component }) => !!component?.toLowerCase().includes('overall')
  );

  const overallColor = overallReading?.color;

  const overallSignal = overallReading?.reading;

  if (readingOnly) {
    if (isLoading) {
      return <Skeleton style={{ marginBottom: 0 }} />;
    } else {
      return (
        <div className='center'>
          <span
            data-testid='overall-reading'
            className='uppercase text-white text-center rounded-sm py-1 px-2 block w-full font-semibold md:py-1.5 md:px-0 text-sm lg:text-base max-w-[10rem]'
            style={{ backgroundColor: overallColor }}>
            {overallSignal}
          </span>
        </div>
      );
    }
  }

  const components = readings?.filter(
    ({ component }) => !component?.toLowerCase().includes('overall')
  );

  return (
    <div className='w-full'>
      {!condensed && (
        <div className='p-4 md:p-6'>
          <div className='flex flex-col items-center justify-center'>
            {isLoading ? (
              <Skeleton height={40} width={200} />
            ) : (
              <div>
                <BlockedText
                  text={overallSignal}
                  backgroundColor={overallColor}
                  size='large'
                  testId='overall-label'
                />
                <p className='text-xl font-bold mt-2 text-center' data-testid='overall-value'>
                  {overallReading?.value}
                </p>
              </div>
            )}
          </div>
        </div>
      )}
      {condensed && isLoading && <Skeleton width={400} />}
      {isLoading ? (
        <div className='mx-auto max-w-[400px] mt-1'>
          <Skeleton count={4} className='mb-6 text-xl' />
        </div>
      ) : (
        <ul className='mx-auto max-w-[400px]'>
          {condensed && !isLoading && (
            <li
              className='flex justify-between items-center py-3 border-b border-solid border-zinc-200 last:border-none'
              style={condensedPadding}>
              <span className='font-semibold text-sm uppercase'>{overallReading.component}</span>
              <BlockedText text={overallSignal} backgroundColor={overallColor} />
              {!condensed && <span>{overallReading.value}</span>}
            </li>
          )}
          {components.map(({ component, reading, value, color }) => {
            return (
              <li
                className='flex space-between items-center py-3 border-b border-solid border-zinc-200 last:border-none'
                key={component}
                style={condensed ? condensedPadding : undefined}>
                <span className='text-15 flex-1 mr-4'>{coreModelComponentMap[component]}</span>
                <div className='flex' style={{ flex: condensed || !value ? 'initial' : 1 }}>
                  <span
                    className='w-4 h-4 block rounded-full'
                    style={{ backgroundColor: color }}></span>
                  <span className='text-13 uppercase inline-block ml-1.5 font-semibold w-20'>
                    {reading}
                  </span>
                </div>
                {!condensed && !!value && <span className='basis-[30px] text-right'>{value}</span>}
              </li>
            );
          })}
        </ul>
      )}
      {Legend && <Legend />}
    </div>
  );
}

export default memo(CoreModelTable);
