import { CoreModelCode, coreModelColorMap, coreModelSignalMap } from '@3fourteen/core';
import { useBitcoin } from 'hooks/useBitcoin';
import { memo, useMemo } from 'react';

import { CoreModelCard } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface BitcoinCardProps {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function BitcoinCard({ cardId, condensed = true }: BitcoinCardProps) {
  const { bitcoin } = useBitcoin();

  const coreReadings = useMemo(() => {
    return bitcoin?.readings.map(({ component, value }) => {
      const color = coreModelColorMap[value];
      return {
        color,
        component,
        reading: coreModelSignalMap[CoreModelCode.bitcoin][value],
      };
    });
  }, [bitcoin]);

  return (
    <CoreModelCard
      cardId={cardId}
      readings={coreReadings}
      title='Bitcoin'
      readingOnly={condensed}
      date={bitcoin?.date}
      href={routes.models.bitcoin}
    />
  );
}

export default memo(BitcoinCard);
