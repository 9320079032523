import { CoreModelReading } from '@3fourteen/core';
import { memo } from 'react';

import { Card, CoreModelTable, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { LinkProps } from 'next/link';

interface CoreModelCardProps {
  cardId: CardProps['cardId'];
  readings: CoreModelReading[];
  date: string;
  title: 'Crude' | 'Gold' | 'S&P Risk' | 'Bitcoin';
  readingOnly?: boolean;
  href?: LinkProps['href'];
}

function CoreModelCard({
  cardId,
  readings,
  date,
  title,
  readingOnly = false,
  href,
}: CoreModelCardProps) {
  return (
    <Card cardId={cardId} heading={title} href={href} leftEl={<RebalDate date={date} />}>
      <div className='p-4 md:p-6'>
        <CoreModelTable readings={readings} readingOnly={readingOnly} date={date} />
      </div>
    </Card>
  );
}

export default memo(CoreModelCard);
