'use client';

import { OurPick } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOurPicks } from 'hooks/useOurPicks';
import { memo, useEffect, useState } from 'react';

import { StocksCardTable } from 'components';

function OurPicksCardTable() {
  const { picks } = useOurPicks();
  const [ourPicks, setOurPicks] = useState<OurPick[]>(null);
  const isMediumScreen = useMediaQuery(1024);
  const numOfVisible = isMediumScreen ? 4 : 6;

  useEffect(() => {
    if (!!picks) {
      /** Filter out inactive picks, sort highest -> lowest, show top 4 or 6 picks */
      const sorted = picks
        .filter(({ inactiveDate }) => !inactiveDate)
        .sort((a, b) => (a.pct_change < b.pct_change ? 1 : -1));

      setOurPicks([...sorted]);
    }
  }, [picks, numOfVisible]);

  return <StocksCardTable stocks={ourPicks} />;
}

export default memo(OurPicksCardTable);
