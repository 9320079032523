'use client';

import { assetNameMap, chartColors, RaaReadingsResponse, ReadingsFilename } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { memo, useEffect, useState } from 'react';

import { ReadingsTable } from 'components';

interface RaaCardReadingsTableProps {
  data: RaaReadingsResponse;
  condensed?: boolean;
}

function RaaCardReadingsTable({ data, condensed = true }: RaaCardReadingsTableProps) {
  const [raaData, setRaaData] = useState(null);
  const isMediumScreen = useMediaQuery(1024);

  useEffect(() => {
    if (!!data) {
      let readings = [];

      data.readings.forEach(({ name, weight, benchmark_weight, children }, i) => {
        const color = chartColors[i];
        const parentName = isMediumScreen ? assetNameMap[name] || name : name;

        const parent = {
          name: parentName,
          weight,
          benchmark: benchmark_weight,
          color,
          children,
        };

        readings.push(parent);

        if (!condensed && !!children && children.length > 1) {
          children.forEach((child) => {
            const childName = isMediumScreen ? assetNameMap[child.name] || child.name : child.name;

            readings.push({
              name: childName,
              benchmark: child.benchmark_weight,
              weight: child.weight,
              isChild: true,
              color,
            });
          });
        }
      });

      setRaaData({ readings });
    }
  }, [data, isMediumScreen, condensed]);

  if (isMediumScreen && condensed) return null;

  return (
    <div className='max-w-xs mx-auto w-full p-4 md:p-6'>
      <ReadingsTable data={raaData} condensed={condensed} dates={data?.dates} />
    </div>
  );
}

export default memo(RaaCardReadingsTable);
