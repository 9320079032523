import { classNames } from 'helpers/classNames';
import { memo } from 'react';

import { CardRowSkeleton, CaretIcon } from 'components';

export interface ReadingsRowItem {
  label: string;
  value?: string;
  caretDirection?: 'up' | 'down';
}

interface ReadingsRowProps {
  readings: ReadingsRowItem[];
}

function ReadingsRow({ readings }: ReadingsRowProps) {
  return (
    <>
      {!readings ? (
        <CardRowSkeleton numberOfItems={4} />
      ) : (
        <ul className='flex justify-between'>
          {readings.map(({ label, value, caretDirection }, i) => {
            const labelClasses =
              'mb-1 uppercase inline-block px-1 text-xs md:text-sm whitespace-nowrap text-ellipsis overflow-hidden max-w-[calc((100vw_-_52px)_/_4)] md:max-w-auto';

            return (
              <li
                key={`${label}-${i}`}
                className='flex-1 flex flex-col items-center border-r border-solid border-zinc-200 last:border-none'
              >
                <span
                  className={classNames(
                    labelClasses,
                    !value ? 'text-15 md:text-base md:mt-2' : ''
                  )}
                >
                  {label}
                </span>
                {!!value && (
                  <div className='flex items-center font-semibold text-15'>
                    <span>{value}</span>
                    {!!caretDirection && (
                      <CaretIcon direction={caretDirection} />
                    )}
                  </div>
                )}
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
}

export default memo(ReadingsRow);
