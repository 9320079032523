'use client';

import { chartColors, YoAsset } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useYo } from 'hooks/useYo';
import { memo, useEffect, useState } from 'react';

import { ReadingsTable } from 'components';

interface YoCardReadingsTableProps {
  condensed?: boolean;
}

function YoCardReadingsTable({ condensed = true }: YoCardReadingsTableProps) {
  const { yo } = useYo();
  const [yoReadings, setYoReadings] = useState(null);
  const isMediumScreen = useMediaQuery(1024);

  useEffect(() => {
    if (!!yo) {
      let readings;

      // If condensed, slice to show top 4
      // Else, sort by name and show all
      if (condensed) {
        readings = [...yo.readings].slice(0, 4).map((asset, index) => ({
          ...asset,
          color: chartColors[index],
        }));
      } else {
        const sorted = yo.readings
          ? [...yo.readings].sort((a: YoAsset, b: YoAsset) => (a.name < b.name ? -1 : 1))
          : [];

        readings = sorted.map((asset, index) => ({
          ...asset,
          color: chartColors[index],
        }));
      }

      setYoReadings([...readings]);
    }
  }, [yo, isMediumScreen, condensed]);

  if (isMediumScreen && condensed) return null;

  return (
    <ReadingsTable
      condensed={condensed}
      data={
        !!yo
          ? {
              readings: yoReadings,
            }
          : undefined
      }
      dates={yo?.dates}
    />
  );
}
export default memo(YoCardReadingsTable);
