import { memo } from 'react';

import { Card, OurPicksCardRow, OurPicksCardTable } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface OurPicksCard {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function OurPicksCard({ cardId, condensed = true }) {
  const title = condensed
    ? 'Our Picks: Top Performing'
    : 'Our Picks: Active Picks';
  return (
    <Card
      cardId={cardId}
      heading={title}
      href={routes.stockSelection.ourPicks}
      className={
        !condensed
          ? 'pt-4 px-4 pb-3 md:pt-5 md:px-6 md:pb-4'
          : 'pt-4 pb-3 md:py-5'
      }
    >
      {condensed ? <OurPicksCardRow /> : <OurPicksCardTable />}
    </Card>
  );
}

export default memo(OurPicksCard);
