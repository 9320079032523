'use client';

import { ArrowLongDownIcon, ArrowLongUpIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  RowSelectionState,
  SortingState,
  useReactTable,
} from '@tanstack/react-table';
import { FieldArray, Form, Formik, FormikProps, FormikValues } from 'formik';
import { classNames } from 'helpers/classNames';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import * as Yup from 'yup';

import { columns, PortfolioRow } from './columns';

interface MyPortfoliosTableProps {
  portfolios: PortfolioRow[];
}

function Filter({ column, table }: any) {
  const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return typeof firstValue === 'number' ? (
    <div className='flex space-x-2'>
      <input
        type='number'
        value={(columnFilterValue as [number, number])?.[0] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [e.target.value, old?.[1]])
        }
        placeholder='Min'
        className='w-20 rounded-full text-sm py-1 mt-2'
      />
      <input
        type='number'
        value={(columnFilterValue as [number, number])?.[1] ?? ''}
        onChange={(e) =>
          column.setFilterValue((old: [number, number]) => [old?.[0], e.target.value])
        }
        placeholder='Max'
        className='w-20 rounded-full text-sm py-1 mt-2'
      />
    </div>
  ) : (
    <div className='mr-4'>
      <input
        type='text'
        value={(columnFilterValue ?? '') as string}
        onChange={(e) => column.setFilterValue(e.target.value)}
        placeholder='Search...'
        className='w-full rounded-full text-sm py-1 mt-2 font-medium border-gray-300'
      />
    </div>
  );
}

const MyPortfoliosSchema = Yup.object().shape({
  portfolios: Yup.array().of(
    Yup.object()
      .shape({
        model_name: Yup.string().default('').required('Your model needs a name'),
        alpha_model: Yup.object().shape({
          id: Yup.string().required().default(''),
          name: Yup.string().required().default(''),
        }),
        benchmark_series_id: Yup.string().required().default(''),
        rebalance_config: Yup.string().required().default('monthly'),
      })
      .required()
  ),
});

function MyPortfoliosTable({ portfolios }: MyPortfoliosTableProps) {
  const localSort = 'my-portfolios-sort';
  const tableForm = useRef<FormikProps<FormikValues>>(null);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});
  const [data, setData] = useState([...portfolios]);
  const [sorting, setSorting] = useState<SortingState>(
    JSON.parse(localStorage.getItem(localSort)) || []
  );

  useEffect(() => {
    localStorage.setItem(localSort, JSON.stringify(sorting));
  }, [sorting, localSort]);

  useEffect(() => {
    setData([...portfolios]);
  }, [portfolios]);

  const cols = useMemo(() => columns, []);

  const table = useReactTable({
    data,
    columns: cols,
    state: {
      sorting,
      rowSelection,
    },
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    onRowSelectionChange: setRowSelection,
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    debugTable: true,
    enableMultiRowSelection: false,
  });

  return (
    <div>
      <Formik
        key='my-portfolios-form'
        validationSchema={MyPortfoliosSchema}
        innerRef={tableForm}
        initialValues={{
          portfolios,
        }}
        onSubmit={(values) => {
          console.log('values from asset table form submission: ', values);
        }}>
        {(formik) => {
          const { errors } = formik;

          return (
            <Form className='overflow-auto border border-solid border-gray-200'>
              <FieldArray name='portfolios'>
                {(arrayHelpers) => (
                  <table className='w-full border-collapse border-spacing-0'>
                    <thead>
                      {table.getHeaderGroups().map((headerGroup, i) => {
                        return (
                          <tr key={i}>
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.column.id}
                                className={classNames(
                                  header.column.getIsFiltered() || header.column.getIsSorted()
                                    ? 'bg-teal-800 text-white border-teal-800'
                                    : 'bg-gray-100 border-gray-200',
                                  'text-left py-2 !pl-4 border border-solid  z-10 shadow-none text-sm font-bold'
                                )}>
                                {header.column.getCanSort() ? (
                                  <button
                                    onClick={header.column.getToggleSortingHandler()}
                                    className='flex items-center'>
                                    <span className='text-15'>
                                      {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext()
                                      )}
                                    </span>
                                    {{
                                      asc: <ArrowLongUpIcon className='h-4 w-4' />,
                                      desc: <ArrowLongDownIcon className='h-4 w-4' />,
                                    }[header.column.getIsSorted() as string] ?? (
                                      <ArrowsUpDownIcon className='h-4 w-4 text-teal-800/50 mt-0.5 ml-1' />
                                    )}
                                  </button>
                                ) : (
                                  flexRender(header.column.columnDef.header, header.getContext())
                                )}
                                {header.column.getCanFilter() ? (
                                  <div>
                                    <Filter column={header.column} table={table} />
                                  </div>
                                ) : null}
                              </th>
                            ))}
                          </tr>
                        );
                      })}
                    </thead>
                    <tbody>
                      {table.getRowModel().rows.map((row) => {
                        return (
                          <tr key={row.id} className='border-b border-solid border-gray-200'>
                            {row.getVisibleCells().map((cell) => {
                              return (
                                <td
                                  key={cell.id}
                                  className={classNames(
                                    row.getIsSelected() ? 'bg-teal-50' : '',
                                    'py-1 pl-4 relative'
                                  )}>
                                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      })}
                    </tbody>
                    <tfoot className='border-y border-solid border-gray-300'>
                      {table.getFooterGroups().map((footerGroup, i) => {
                        return (
                          <tr key={i}>
                            {footerGroup.headers.map((footer) => (
                              <td key={footer.column.id} className=''>
                                {flexRender(footer.column.columnDef.footer, footer.getContext())}
                              </td>
                            ))}
                          </tr>
                        );
                      })}
                    </tfoot>
                  </table>
                )}
              </FieldArray>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

MyPortfoliosTable.displayName = 'MyPortfoliosTable';

export default memo(MyPortfoliosTable);
