import { classNames } from 'helpers/classNames';
import { InputHTMLAttributes, memo } from 'react';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  theme?: 'light' | 'dark';
}

function Input({ error, theme = 'light', ...rest }: InputProps) {
  return (
    <div className='flex h-[72px] flex-col w-[300px]'>
      <input
        {...rest}
        className={classNames(
          !!error
            ? 'border-terracotta-500 border-[3px]'
            : 'border border-neutral-200',
          'block h-11 w-full px-3 rounded-sm  border-solid  text-gray-800'
        )}
      />
      {!!error && (
        <p
          className={classNames(
            theme === 'dark' ? 'text-orange-600' : 'text-terracotta-500',
            'text-13 font-semibold mb-0'
          )}
          data-testid='error'
        >
          {error}
        </p>
      )}
    </div>
  );
}

export default memo(Input);
