'use client';

import { formatWeight, RaaReadingsResponse, ReadingsFilename } from '@3fourteen/core';
import { memo, useEffect, useState } from 'react';

import { ReadingsRow } from 'components';

import { ReadingsRowItem } from '../../ReadingsRow/component';

interface RaaCardReadingsRowProps {
  data: RaaReadingsResponse;
}

function RaaCardReadingsRow({ data }: RaaCardReadingsRowProps) {
  const [raaData, setRaaData] = useState(null);

  useEffect(() => {
    if (!!data) {
      let readings = [];

      data.readings.forEach(({ name, weight, benchmark_weight }, i) => {
        const change = weight[1] - benchmark_weight;

        const rowReading: ReadingsRowItem = {
          label: name,
          value: formatWeight(weight[1], 0),
          caretDirection: change === 0 ? undefined : change > 0 ? 'up' : 'down',
        };

        readings.push(rowReading);
      });

      setRaaData({ readings });
    }
  }, [data]);

  return (
    <div className='block lg:hidden pt-3 pb-3'>
      <p className='text-xs uppercase font-semibold mb-2 pb-1 text-gray-500 mx-4'>
        Official Weights
      </p>
      <ReadingsRow readings={raaData?.readings} />
    </div>
  );
}

export default memo(RaaCardReadingsRow);
