import { formatWeight, RealAAAsset, RealAAChildAsset, RealAAParentAsset, YoAsset } from '@3fourteen/core';
import { classNames } from 'helpers/classNames';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { Children, memo } from 'react';

import { PercentageCircle, WeightChangeIcon } from 'components';

export interface ReadingsTableItemProps {
  asset: {
    name: RealAAAsset['name'] | YoAsset['name'];
    benchmark: RealAAAsset['benchmark_weight'];
    weight: RealAAAsset['weight'] | YoAsset['weight'];
    ticker?: RealAAChildAsset['ticker'] | YoAsset['ticker'];
    children?: RealAAParentAsset['children'];
    isChild?: boolean;
  };
  condensed: boolean;
}

function ReadingsTableItem({ asset, condensed }: ReadingsTableItemProps) {
  const { name, weight, isChild = false, children, benchmark } = asset;
  const isSmallScreen = useMediaQuery(768);
  const hGutter = !isChild ? 0 : isSmallScreen ? '1rem' : '2.5rem';
  const isParent = !!children && !condensed;
  const nameClass = isParent ? 'uppercase text-sm font-semibold' : '';
  const decimalPlaces = benchmark ? 0 : 1;

  return (
    <div
      className='flex justify-between items-center py-0.25'
      style={{
        marginLeft: hGutter,
      }}
    >
      <div className='flex items-center py-1.5 '>
        <span className={classNames('whitespace-nowrap text-15', nameClass)}>
          {name}
        </span>
      </div>
      <div className='flex'>
        <div className='flex items-center'>
          <span className='w-20 inline-block text-right font-semibold'>
            {formatWeight(weight[1], decimalPlaces)}
          </span>
          <WeightChangeIcon
            currentWeight={weight[1]}
            previousWeight={benchmark ? benchmark : weight[2]} // YO doesn't have benchmark and uses previous rebal date
          />
        </div>
      </div>
    </div>
  );
}

export default memo(ReadingsTableItem);
