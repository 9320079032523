'use client';

import { FullCycleStock } from '@3fourteen/core';
import { memo, useEffect, useState } from 'react';

import { StocksCardTable } from 'components';

interface FullCycleCardTableProps {
  data: FullCycleStock[];
}

function FullCycleCardTable({ data }: FullCycleCardTableProps) {
  const [stocks, setStocks] = useState<FullCycleStock[]>(null);

  useEffect(() => {
    if (data) {
      /** Filter out closed out stocks */
      const sorted = data.filter(({ inactive_date }) => !inactive_date);

      setStocks([...sorted]);
    }
  }, [data]);

  return <StocksCardTable stocks={stocks} />;
}

export default memo(FullCycleCardTable);
