'use client';

import { FullCycleStock } from '@3fourteen/core';
import { memo, useEffect, useState } from 'react';

import { ReadingsRow } from 'components';

interface FullCycleCardRowProps {
  data?: FullCycleStock[];
}

function FullCycleCardRow({ data }: FullCycleCardRowProps) {
  const [stocks, setStocks] = useState(undefined);

  useEffect(() => {
    if (data) {
      /** Filter out inactive picks, sort highest -> lowest, show top 4 or 6 picks */
      const sorted = data
        .filter(({ is_new }) => is_new)
        .map(({ ticker }) => ({
          label: ticker,
        }));

      setStocks([...sorted]);
    }
  }, [data]);

  return <ReadingsRow readings={stocks} />;
}

export default memo(FullCycleCardRow);
