import { formatPercent, FullCycleStock, OurPick } from '@3fourteen/core';
import { memo } from 'react';

import { CardRowSkeleton, CaretIcon } from 'components';

interface StocksCardTableProps {
  stocks: OurPick[] | FullCycleStock[];
}

function StocksCardTable({ stocks }: StocksCardTableProps) {
  return (
    <div>
      {!stocks ? (
        <div>
          {[...Array(8)].map((_, i) => {
            return (
              <div style={{ marginBottom: 6, marginTop: 12 }} key={i}>
                <CardRowSkeleton numberOfItems={3} height={20} />
              </div>
            );
          })}
        </div>
      ) : (
        <div className='mx-auto'>
          <p className='flex justify-between mb-4'>
            <span className='font-semibold uppercase flex-1 text-sm'>
              Ticker
            </span>
            <span className='font-semibold uppercase flex-1 text-sm text-center'>
              %CHG
            </span>
            <span className='font-semibold uppercase flex-1 text-sm text-right'>
              S&P500 %CHG
            </span>
          </p>
          {stocks.map(({ ticker, pct_change, sp500_change }) => (
            <div
              className='py-2 text-15 md:text-base border-b border-solid border-zinc-200 flex justify-between last-of-type:border-none'
              key={ticker}
            >
              <div className='flex-1'>
                <span className='w-[110px]'>{ticker}</span>
              </div>
              <div className='flex-1'>
                <div className='flex justify-center mx-auto font-semibold'>
                  <span>{formatPercent(pct_change)}</span>
                  <CaretIcon
                    direction={
                      pct_change === 0 ? null : pct_change > 0 ? 'up' : 'down'
                    }
                  />
                </div>
              </div>
              <div className='flex-1'>
                <span className='block text-right'>
                  {formatPercent(sp500_change)}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export default memo(StocksCardTable);
