import { fetchOurPicks, OurPick } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useOurPicks() {
  const { data: picks, ...rest } = useQuery<OurPick[]>({
    queryKey: ['ourPicks'],
    queryFn: fetchOurPicks,
  });

  return {
    picks,
    ...rest,
  };
}
