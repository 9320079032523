'use client';

import { formatPercent } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useOurPicks } from 'hooks/useOurPicks';
import { memo, useEffect, useState } from 'react';

import { ReadingsRow } from 'components';

function OurPicksCardRow() {
  const { picks } = useOurPicks();
  const [ourPicks, setOurPicks] = useState(null);
  const isMediumScreen = useMediaQuery(1024);
  const numOfVisible = isMediumScreen ? 4 : 6;

  useEffect(() => {
    if (!!picks) {
      /** Filter out inactive picks, sort highest -> lowest, show top 4 or 6 picks */
      const sorted = picks
        .filter(({ inactiveDate }) => !inactiveDate)
        .sort((a, b) => (a.pct_change < b.pct_change ? 1 : -1))
        .slice(0, numOfVisible)
        .map(({ ticker, pct_change }) => ({
          label: ticker,
          value: formatPercent(pct_change),
          caretDirection: pct_change === 0 ? undefined : pct_change > 0 ? 'up' : 'down',
        }));

      setOurPicks([...sorted]);
    }
  }, [picks, numOfVisible]);

  return <ReadingsRow readings={ourPicks} />;
}

export default memo(OurPicksCardRow);
