import dayjs from 'dayjs';
import { useYo } from 'hooks/useYo';
import { memo } from 'react';

import { Card, CaretIcon, RebalDate, YoCardReadingsRow, YoCardReadingsTable } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface YoCardProps {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function YoCard({ cardId, condensed = true }: YoCardProps) {
  const { yo } = useYo();

  return (
    <Card
      cardId={cardId}
      heading='Yield Optimizer'
      href={routes.models.yo}
      leftEl={<RebalDate date={yo?.dates[1]} />}>
      <div className='px-4 md:px-6'>
        <YoCardReadingsTable condensed={condensed} />
      </div>
      {condensed && <YoCardReadingsRow />}
      <div className='px-2 pb-1.5 pt-1 lg:pt-2 lg:pb-2 lg:mt-2 flex lg:justify-between border-t border-solid border-gray-200 lg:border-none'>
        <div className='flex items-center mr-4 lg:mr-0'>
          <CaretIcon direction='up' />
          <span className='text-xs inline-block tracking-tight'>
            Increase since
            <span className='ml-0.5'>{dayjs(yo?.dates[2]).format('MM/DD')}</span>
          </span>
        </div>
        <div className='flex items-center'>
          <CaretIcon direction='down' />
          <span className='text-xs inline-block tracking-tight'>
            Decrease since
            <span className='ml-0.5'>{dayjs(yo?.dates[2]).format('MM/DD')}</span>
          </span>
        </div>
      </div>
    </Card>
  );
}
export default memo(YoCard);
