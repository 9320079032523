import { ReadingsFilename } from '@3fourteen/core';
import { useRaa } from 'hooks/useRaa';
import { memo } from 'react';

import { Card, CaretIcon, RaaCardReadingsRow, RaaCardReadingsTable, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface RaaCardProps {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function RaaCard({ cardId, condensed = true }: RaaCardProps) {
  const { raa } = useRaa(ReadingsFilename.RAA_V2_20);

  return (
    <Card
      cardId={cardId}
      heading='Real Asset Allocation'
      href={routes.models.RAA_V2_20}
      leftEl={!!raa && <RebalDate date={raa.dates[1]} />}>
      <div className='flex flex-col h-full justify-between'>
        <RaaCardReadingsTable condensed={condensed} data={raa} />
        {condensed && <RaaCardReadingsRow data={raa} />}
        <div className='px-4 pb-1.5 pt-1 lg:pt-2 lg:pb-2.5 lg:mt-2 flex lg:justify-between border-t border-solid border-gray-200 lg:border-none'>
          <div className='flex items-center mr-4 lg:mr-0'>
            <CaretIcon direction='up' />
            <span className='text-xs inline-block'>Over benchmark</span>
          </div>
          <div className='flex items-center'>
            <CaretIcon direction='down' />
            <span className='text-xs inline-block'>Under benchmark</span>
          </div>
        </div>
      </div>
    </Card>
  );
}

export default memo(RaaCard);
