'use client';

import { Chart, Commentary, CustomPost, Retrospective, useToggleBoolean } from '@3fourteen/core';
import { downloadFile } from 'helpers/downloadFile';
import { usePostBy } from 'hooks/usePostBy';
import { useSignedCloudfrontUrl } from 'hooks/useSignedCloudfrontUrl';
import { memo, useCallback, useEffect, useMemo } from 'react';

import { BottomBar, CommentaryRenderer, FAB, FavoriteButton, LoadingDots, PdfViewer, Retro } from 'components';
import { useHistory } from 'contexts/history';
import Head from 'next/head';
import { usePathname, useRouter } from 'next/navigation';
import { trackToggledPostViewingPreference, trackViewedPost } from 'services/mixpanel';
import { routes } from 'services/routes';

function PostRenderer({ uri }) {
  const history = useHistory();
  const router = useRouter();
  const pathname = usePathname();
  const { post } = usePostBy({ uri });
  const [viewingHtml, _, viewHtml, viewPdf] = useToggleBoolean(false);
  const splitUrl = (pathname || '').split('#');
  const hasAnchor = !!splitUrl[1];

  const { pdfKey, dataUrlKey } = useMemo(() => {
    if (post) {
      const isoDate = post?.date.split('T')[0];
      const year = isoDate?.split('-')[0];
      const filename = post?.filename || 'report.pdf';
      const dataUrlAttrs = (post as Chart)?.dataUrl?.split('/');
      const dataUrlFilename = dataUrlAttrs?.length
        ? dataUrlAttrs[dataUrlAttrs.length - 1]
        : undefined;

      return post?.categorySlug === 'charts'
        ? { pdfKey: post?.filename, dataUrlKey: dataUrlFilename }
        : { pdfKey: `${post?.categorySlug}/${year}/${isoDate}/${filename}` };
    } else {
      return {
        pdfKey: undefined,
        dataUrlKey: undefined,
      };
    }
  }, [post]);

  const { url, error } = useSignedCloudfrontUrl(pdfKey);
  const { url: signedDataUrl } = useSignedCloudfrontUrl(dataUrlKey);

  const onViewHtml = useCallback(() => {
    trackToggledPostViewingPreference(true);
    localStorage.setItem('isHtml', 'true');
    viewHtml();
  }, [viewHtml]);

  const onViewPdf = useCallback(() => {
    trackToggledPostViewingPreference(false);
    localStorage.setItem('isHtml', 'false');
    viewPdf();
  }, [viewPdf]);

  useEffect(() => {
    const isHtml = localStorage.getItem('isHtml');
    const isViewingHtml = isHtml === 'true';

    if (isViewingHtml || hasAnchor) {
      viewHtml();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!post) {
      trackViewedPost(
        post.title,
        post.categorySlug,
        (post as Commentary | Chart).filename,
        viewingHtml
      );
    }
  }, [post, viewingHtml]);

  const HeadTag = useMemo(() => {
    const pageTitle = post?.title;
    return (
      <>
        <Head>
          <title>{`${pageTitle} | 3FR`}</title>
          <meta property='og:title' content={pageTitle} key='title' />
        </Head>
      </>
    );
  }, [post]);

  const downloadData = useCallback(() => {
    if (!!(post as Chart)?.dataUrl) {
      const file = (post as Chart)?.filename.split('.').shift();

      downloadFile(signedDataUrl, file);
    }
  }, [post, signedDataUrl]);

  const buttons = useMemo(() => {
    const btns = [
      {
        El: (
          <FavoriteButton
            post={post as Chart | Commentary}
            containerClass='!w-full'
            className='!text-white'
          />
        ),
      },
      {
        icon: 'close',
        text: 'Close',
        onClick: () => {
          const previousRoute = history[1];

          if (!previousRoute || previousRoute === routes.login) {
            router.push('/');
          } else {
            router.back();
          }
        },
      },
    ];

    if (!!(post as Commentary)?.content) {
      btns.unshift({
        icon: viewingHtml ? 'pdffile1' : 'HTML',
        text: viewingHtml ? 'PDF' : 'HTML',
        onClick: viewingHtml ? () => onViewPdf() : () => onViewHtml(),
      });
    }

    if (!!(post as Chart)?.dataUrl) {
      btns.unshift({
        icon: 'download',
        text: 'Download',
        onClick: downloadData,
      });
    }

    return btns;
  }, [downloadData, viewingHtml, onViewHtml, onViewPdf, router, post]);

  if (!post) {
    return (
      <div className='h-screen flex items-center justify-center'>
        <LoadingDots color='#999' size='regular' />
      </div>
    );
  }

  if (post?.categorySlug === 'retrospectives') {
    return (
      <>
        {HeadTag}
        <Retro retro={post as Retrospective} />
      </>
    );
  }

  if (post?.categorySlug === 'custom') {
    return (
      <>
        {HeadTag}
        <div dangerouslySetInnerHTML={{ __html: (post as CustomPost)?.content }} />
      </>
    );
  }

  if (viewingHtml && !!(post as Commentary)?.content) {
    return (
      <section className='relative z-[999]'>
        {HeadTag}
        <CommentaryRenderer post={post as Commentary} />
        <div className='hidden lg:block'>
          <div className='fixed z-[4] bottom-24 mb-8 md:mb-0 right-4 md:right-6  lg:right-10'>
            <div className='btn btn-primary w-[130px]'>
              <FavoriteButton
                post={post as Chart | Commentary}
                containerClass='!w-full'
                className='!text-white'
              />
            </div>
          </div>
          <FAB text='View PDF' onClick={onViewPdf} />
        </div>
        <BottomBar buttons={buttons} />
      </section>
    );
  }

  return (
    <section className='relative z-[999]'>
      {HeadTag}
      {url && (
        <PdfViewer
          url={url}
          filename={(post as Commentary | Chart)?.filename}
          meta={{ post: post as Commentary | Chart }}
        />
      )}
      {error && <p className='text-center mt-[45vh]'>{error}</p>}
      {!!(post as Commentary)?.content && (
        <div className='hidden lg:block'>
          <FAB text='View HTML' onClick={onViewHtml} />
        </div>
      )}
      {signedDataUrl && (
        <div className='hidden lg:block'>
          <FAB text='Download Data' onClick={downloadData} />
        </div>
      )}

      <BottomBar buttons={buttons} />
    </section>
  );
}

PostRenderer.displayName = 'PostRenderer';

export default memo(PostRenderer);
