import { useFullCycle } from 'hooks/useFullCycle';
import { memo } from 'react';

import { Card, FullCycleCardRow, FullCycleCardTable, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface FullCycleCardProps {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function FullCycleCard({ cardId, condensed = true }: FullCycleCardProps) {
  const { fullCycle } = useFullCycle();
  const title = condensed
    ? 'Full Cycle Trend: Latest Buys'
    : 'Full Cycle Trend: Active Stocks';

  return (
    <Card
      cardId={cardId}
      heading={title}
      href={routes.stockSelection.fullCycleTrend}
      className={
        !condensed
          ? 'pt-4 px-4 pb-3 md:pt-5 md:px-6 md:pb-4'
          : 'pt-4 pb-3 md:py-5'
      }
      leftEl={<RebalDate date={fullCycle?.date} />}
    >
      {condensed ? (
        <FullCycleCardRow data={fullCycle?.data} />
      ) : (
        <FullCycleCardTable data={fullCycle?.data} />
      )}
    </Card>
  );
}

export default memo(FullCycleCard);
