'use client';

import { formatWeight } from '@3fourteen/core';
import { useYo } from 'hooks/useYo';
import { memo, useEffect, useState } from 'react';

import { ReadingsRow } from 'components';

function YoCardReadingsRow() {
  const { yo } = useYo();
  const [yoReadings, setYoReadings] = useState(null);

  useEffect(() => {
    if (!!yo) {
      const readings = [...yo.readings].slice(0, 4).map(({ name, weight }) => {
        const change = weight[1] - weight[2];
        return {
          label: name,
          value: formatWeight(weight[1]),
          caretDirection: change === 0 ? null : change > 0 ? 'up' : 'down',
        };
      });

      setYoReadings([...readings]);
    }
  }, [yo]);

  return (
    <div className='py block lg:hidden'>
      <p className='text-xs uppercase font-semibold mb-2 pb-1 text-gray-500 mx-4'>
        Official Weights
      </p>
      <ReadingsRow readings={yoReadings} />
    </div>
  );
}
export default memo(YoCardReadingsRow);
