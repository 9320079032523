import {
  PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID,
  PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID,
} from '@3fourteen/core';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { useMember } from 'hooks/useMember';
import { memo } from 'react';

import { CancelSubscriptionModal, ResumeSubcriptionModal } from 'components';

function MySubscriptions() {
  const { memberships, subscriptions, transactions } = useMember();
  const pbMembership = memberships?.find(
    ({ id }) =>
      id === PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID ||
      id === PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID
  );
  const pbSubscription = subscriptions?.find(
    ({ membership }) =>
      membership === `${PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID}` ||
      membership === `${PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID}`
  );

  const pbTxn = transactions?.find(
    ({ membership, txn_type }) =>
      (membership === `${PORTFOLIO_BUILDER_MONTHLY_MEMBERSHIP_ID}` ||
        membership === `${PORTFOLIO_BUILDER_ANNUAL_MEMBERSHIP_ID}`) &&
      (txn_type === 'payment' || txn_type === 'subscription_confirmation')
  );

  if (subscriptions && !pbSubscription) {
    return <p>You have no active subscriptions.</p>;
  }

  const isTrialing = pbTxn.txn_type === 'subscription_confirmation';
  const isCancelled = pbSubscription.status === 'cancelled';
  const isActive = pbSubscription.status === 'active';

  return (
    <div className=''>
      {!subscriptions ? (
        <p>Loading...</p>
      ) : (
        <div className='mt-6 border-t border-gray-300 border-solid px-6'>
          <dl>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Name
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {pbMembership.title}
              </dd>
            </div>
            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Created on
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                {dayjs(pbSubscription.created_at).format('MMMM D, YYYY')}
              </dd>
            </div>

            {isTrialing ? (
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>
                  Trial ends
                </dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {pbTxn
                    ? dayjs(pbTxn?.expires_at).format('MMMM D, YYYY')
                    : 'N/A'}
                </dd>
              </div>
            ) : (
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>
                  {isCancelled ? `Expires on` : `Renews on`}
                </dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {pbTxn
                    ? dayjs(pbTxn?.expires_at).format('MMMM D, YYYY')
                    : 'N/A'}
                </dd>
              </div>
            )}

            {!isTrialing && (
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>
                  Last Payment
                </dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {`$${pbTxn.amount} on ${dayjs(pbTxn?.created_at).format(
                    'MMMM D, YYYY'
                  )}`}
                </dd>
              </div>
            )}

            {!isCancelled && (
              <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
                <dt className='text-sm font-medium leading-6 text-gray-900'>
                  Upcoming Payment
                </dt>
                <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0'>
                  {`$${pbSubscription.price} on ${dayjs(
                    pbTxn?.expires_at
                  ).format('MMMM D, YYYY')}`}
                </dd>
              </div>
            )}

            <div className='px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0 border-b border-solid border-gray-200'>
              <dt className='text-sm font-medium leading-6 text-gray-900'>
                Status
              </dt>
              <dd className='mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0 flex items-center'>
                <div className='flex items-center'>
                  {isActive && (
                    <CheckCircleIcon className='h-5 w-5 text-emerald-600 mr-1' />
                  )}
                  <span className='capitalize'>{pbSubscription.status}</span>
                </div>
                <div className='ml-6'>
                  {isCancelled ? (
                    <ResumeSubcriptionModal id={pbSubscription.id} />
                  ) : (
                    <CancelSubscriptionModal id={pbSubscription.id} />
                  )}
                </div>
              </dd>
            </div>
          </dl>
        </div>
      )}
    </div>
  );
}

MySubscriptions.displayName = 'MySubscriptions';

export default memo(MySubscriptions);
