'use client';

import { fetchYoReadings, YoAsset, YoReadingsResponse } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useYo() {
  const { data: yo, ...rest } = useQuery<YoReadingsResponse>({
    queryKey: ['yoReadings'],
    queryFn: async () => {
      const res = await fetchYoReadings();

      const sorted = [...res.readings].sort((a: YoAsset, b: YoAsset) =>
        a.weight[1] < b.weight[1] ? 1 : -1
      );

      return {
        readings: [...sorted],
        dates: res.dates,
      };
    },
  });

  return {
    yo,
    ...rest,
  };
}
