import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import { ReadingsTableItem } from 'components';

import { ReadingsTableItemProps } from '../ReadingsTableItem/component';

interface ReadingsTableData {
  readings: any[];
}

interface ReadingsTableProps {
  data: ReadingsTableData;
  condensed?: boolean;
  dates: string[];
}

const ReadingsTable = ({ data, condensed = true }: ReadingsTableProps) => {
  const renderAssetReadings = () => {
    return data?.readings?.map((asset: ReadingsTableItemProps['asset'], index) => (
      <li key={`${asset.name}-${index}`} className='border-b border-solid border-zinc-200'>
        <ReadingsTableItem asset={asset} condensed={condensed} />
      </li>
    ));
  };

  const readings = renderAssetReadings();

  return (
    <div>
      {!data ? (
        <Skeleton count={condensed ? 4 : 16} style={{ marginTop: 13, marginBottom: 13 }} />
      ) : (
        <div>
          <p className='text-13 uppercase font-semibold mt-4 mb-2 text-gray-500'>
            Official Weights
          </p>
          <ul>{readings}</ul>
        </div>
      )}
    </div>
  );
};

export default memo(ReadingsTable);
