import { BitcoinReadingsResponse, fetchBitcoinReadings } from '@3fourteen/core';
import { useQuery } from '@tanstack/react-query';

export function useBitcoin() {
  const { data: bitcoin, ...rest } = useQuery<BitcoinReadingsResponse>({
    queryKey: ['bitcoinReadings'],
    queryFn: fetchBitcoinReadings,
  });

  return {
    bitcoin,
    ...rest,
  };
}
