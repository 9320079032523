import { modelUpdatesCategoryId } from '@3fourteen/core';
import { useCommentary } from 'hooks/useCommentary';
import { memo } from 'react';

import { Card, CommentaryItem } from 'components';

function LatestModelUpdateCard() {
  const { commentary } = useCommentary([
    'latest-model-update',
    {
      categories: [modelUpdatesCategoryId],
      first: 1,
    },
  ]);

  return (
    <Card
      cardId='latest-model-update'
      className='py-2 px-4 md:py-3 md:px-6'
      href={`/${commentary?.[0].categorySlug}/${commentary?.[0].slug}`}>
      <CommentaryItem commentary={commentary?.[0]} useHref={false} showExcerpt={false} />
    </Card>
  );
}

export default memo(LatestModelUpdateCard);
