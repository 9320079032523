import { CoreModelCode, CoreModelReading } from '@3fourteen/core';
import dayjs from 'dayjs';
import { useStats } from 'hooks/useStats';
import { memo } from 'react';
import Skeleton from 'react-loading-skeleton';

import {
  CoreModelTable,
  HtmlRenderer,
  LatestUpdateLink,
  PageHeader,
  StatsBox,
  SupportingChart,
  WhitepaperLink,
} from 'components';

interface CoreModelChart {
  title: string;
  url: string;
  height: number;
  width: number;
}

interface CoreModelPageProps {
  statsKey?: CoreModelCode;
  pageTitle: string;
  date: string;
  coreReadings: CoreModelReading[];
  latestUpdate: {
    date: string;
    content: string;
    url?: string;
  };
  charts: CoreModelChart[];
  whitepaperUrl: string;
  isFetching?: boolean;
  Legend?: React.ComponentType;
}

const CoreModelPage = ({
  statsKey,
  pageTitle,
  date,
  coreReadings,
  latestUpdate,
  charts,
  isFetching,
  whitepaperUrl,
  Legend,
}: CoreModelPageProps) => {
  const isLoading = isFetching || !coreReadings;
  const { stats } = useStats(statsKey, {
    enabled: !!statsKey,
  });

  return (
    <div className='bg-zinc-50 pb-16 md:pb-20'>
      <PageHeader
        className='border-none'
        heading={pageTitle}
        rightEl={
          !isLoading && (
            <div className='hidden md:flex'>
              <WhitepaperLink url={whitepaperUrl} textClasses='text-base' />
            </div>
          )
        }
      />

      {!!stats && (
        <div className='md:mt-2 mb-4 md:mb-8'>
          <StatsBox stats={stats} />
        </div>
      )}
      <div className='lg:flex between'>
        <div className='flex-1 justify-center'>
          <div className='flex items-center justify-between pb-2 px-4 md:px-6 border-b border-solid border-neutral-200 pt-4 md:pt-5'>
            <div className='flex items-center justify-between flex-1 md:items-start md:justify-start'>
              <h2 className='font-medium leading-tight'>Readings</h2>
              {!isLoading && (
                <span className='uppercase text-xs text-right relative pl-4 leading-tight md:text-sm md:text-left lg:before:content-[""] before:absolute before:h-5 before:w-px before:bg-neutral-200 before:ml-[-8px]'>
                  {dayjs(date).format('MMM D, YYYY')}
                </span>
              )}
            </div>
          </div>
          <div className='p-4 md:p-6 mb-16'>
            <CoreModelTable
              readings={coreReadings}
              condensed={false}
              date={date}
              Legend={Legend}
            />
            <div
              className='flex md:hidden justify-center'
              style={{ marginTop: 40, marginBottom: 40 }}>
              <WhitepaperLink url={whitepaperUrl} />
            </div>
          </div>
        </div>
        {!!latestUpdate && (
          <div className='flex-1 md:border-l border-solid border-neutral-200'>
            <div className='flex items-center justify-between pb-2 px-4 md:px-6 border-b border-solid border-neutral-200 pt-4 md:pt-5'>
              <div className='flex items-center justify-between flex-1 md:items-start md:justify-start'>
                <h2 className='font-medium leading-tight'>Latest Commentary</h2>
                <span className='uppercase text-xs text-right relative pl-4 leading-tight md:text-sm md:text-left lg:before:content-[""] before:absolute before:h-5 before:w-px before:bg-neutral-200 before:ml-[-8px]'>
                  {dayjs(latestUpdate.date).format('MMM D, YYYY')}
                </span>
              </div>
              {!isLoading && (
                <div className='hidden md:flex'>
                  <LatestUpdateLink
                    url={!!latestUpdate.url ? latestUpdate.url : '#'}
                  />
                </div>
              )}
            </div>
            <div className='px-4 md:px-6 pb-16 md:pb-10'>
              {isLoading ? (
                <div className='py-4 md:py-6'>
                  <Skeleton count={10} />
                </div>
              ) : (
                <div className='mt-4 md:mt-6'>
                  <HtmlRenderer content={latestUpdate.content} />
                  <div className='py-4 md:py-6 flex md:hidden justify-center'>
                    <LatestUpdateLink
                      url={!!latestUpdate.url ? latestUpdate.url : '#'}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div
        className='md:pt-10 md:border-t border-solid border-black/30 mb-4 md:mb-6'
        id='charts'>
        <div className='flex items-center justify-between pb-2 px-4 md:px-6 border-b border-solid border-neutral-200 pt-4 md:pt-5'>
          <div className='flex items-center justify-between flex-1 md:items-start md:justify-start'>
            <h2 className='font-medium leading-tight'>Supporting Charts</h2>
            {!isLoading && (
              <span className='uppercase text-xs text-right relative pl-4 leading-tight md:text-sm md:text-left lg:before:content-[""] before:absolute before:h-5 before:w-px before:bg-neutral-200 before:ml-[-8px]'>
                {dayjs(date).format('MMM D, YYYY')}
              </span>
            )}
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className='text-center py-10 px-4 md:px-6'>
              <Skeleton height={800} width={1200} />
            </div>
          ) : (
            <div>
              {charts.map(({ title, url, height, width }) => {
                return (
                  <SupportingChart
                    key={url}
                    title={title}
                    url={url}
                    height={height}
                    width={width}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(CoreModelPage);
