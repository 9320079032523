'use client';

import { SectorRotationIndex } from '@3fourteen/core';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { useSectorRotation } from 'hooks/useSectorRotation';
import { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';

import { Card, CaretIcon, ReadingsRow, RebalDate } from 'components';
import { CardProps } from 'components/Card/component';
import { routes } from 'services/routes';

interface SectorRotationCardProps {
  cardId: CardProps['cardId'];
  condensed?: boolean;
}

function SectorRotationCard({ cardId, condensed }: SectorRotationCardProps) {
  const isMediumScreen = useMediaQuery(1024);
  const { sectorRotation, isFetching } = useSectorRotation();
  const [sectors, setSectors] = useState(null);
  const showRowDisplay = isMediumScreen && condensed;

  useEffect(() => {
    if (!!sectorRotation) {
      if (condensed) {
        const sorted = [...sectorRotation?.data].sort(
          (a: SectorRotationIndex, b: SectorRotationIndex) => (a.active < b.active ? 1 : -1)
        );
        const overweight = [...sorted].slice(0, 2);
        const underweight = [...sorted].slice(-2);

        if (isMediumScreen) {
          const bestBets = [...overweight, ...underweight];

          const betRows = bestBets.map(({ index, active }) => {
            const caretDirection = active > 0 ? 'up' : active < 0 ? 'down' : undefined;

            return {
              label: index,
              value: `${active}%`,
              caretDirection,
            };
          });
          setSectors(betRows);
        } else {
          setSectors([...overweight, ...underweight]);
        }
      } else {
        const sorted = [...sectorRotation?.data].sort(
          (a: SectorRotationIndex, b: SectorRotationIndex) => (a.index < b.index ? -1 : 1)
        );

        setSectors([...sorted]);
      }
    }
  }, [sectorRotation, condensed, isMediumScreen]);

  return (
    <Card
      cardId={cardId}
      heading='Sector Rotation'
      href={routes.models.sector}
      leftEl={<RebalDate date={sectorRotation?.date} />}>
      {!showRowDisplay ? (
        <div className='p-4 md:p-6 max-w-xs mx-auto'>
          {!sectors || isFetching ? (
            <Skeleton count={4} style={{ marginTop: 13, marginBottom: 13 }} />
          ) : (
            <ul>
              {sectors.map(({ index: sector, active }, index) => {
                const iconDirection = active > 0 ? 'up' : active < 0 ? 'down' : undefined;

                return (
                  <li
                    key={`${sector}-${index}`}
                    className='flex justify-between items-center py-2.5 border-t border-solid border-zinc-200 first:border-none'>
                    <span className='text-15'>{sector}</span>
                    <div className='flex items-center font-semibold'>
                      <span className='pr-1.5 pl-6'>{`${active}%`}</span>
                      <CaretIcon direction={iconDirection} />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
        </div>
      ) : (
        <div className='py-4 md:py-6'>
          <ReadingsRow readings={sectors} />
        </div>
      )}
    </Card>
  );
}

export default memo(SectorRotationCard);
